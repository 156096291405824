var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"mb-1",attrs:{"xs6":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"outlined":"","small":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("back")))])],1)],1)],1),_c('v-layout',{staticClass:"mb-6",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{attrs:{"xs6":"","sm4":"","md4":"","lg4":""}},[_c('h2',{staticClass:"headline text-capitalize"},[_vm._v(_vm._s(_vm.$t("new attendance")))])]),_c('v-spacer'),_c('v-flex',{staticClass:"text-right",attrs:{"xs6":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"small":"","dark":"","loading":_vm.loading,"color":_vm.$store.state.secondaryColor},on:{"click":_vm.uploadData}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("save")]),_c('span',{staticClass:"caption text-capitalize"},[_vm._v(_vm._s(_vm.$t("register")))])],1)],1)],1),_c('v-alert',[_c('v-form',{ref:"form"},[_c('v-layout',{staticClass:"caption mt-1",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"px-1",attrs:{"xs12":"","sm6":"","md6":"","lg6":""}},[_c('span',{staticClass:"font-weight-medium text-capitalize"},[_vm._v(_vm._s(_vm.$t("entry time")))]),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.attendance.dateTimeIn,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.attendance, "dateTimeIn", $event)},"update:return-value":function($event){return _vm.$set(_vm.attendance, "dateTimeIn", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.attendance.dateTimeIn),callback:function ($$v) {_vm.$set(_vm.attendance, "dateTimeIn", $$v)},expression:"attendance.dateTimeIn"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimeModal),callback:function ($$v) {_vm.startTimeModal=$$v},expression:"startTimeModal"}},[(_vm.startTimeModal)?_c('v-date-picker',{attrs:{"scrollable":"","full-width":""},model:{value:(_vm.attendance.dateTimeIn),callback:function ($$v) {_vm.$set(_vm.attendance, "dateTimeIn", $$v)},expression:"attendance.dateTimeIn"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startTimeModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.attendance.dateTimeIn)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1):_vm._e()],1)],1),_c('v-flex',{staticClass:"px-1",attrs:{"xs12":"","sm6":"","md6":"","lg6":""}},[_c('span',{staticClass:"font-weight-medium text-capitalize"},[_vm._v(_vm._s(_vm.$t("exit time")))]),_c('v-dialog',{ref:"dialogs",attrs:{"return-value":_vm.attendance.dateTimeOut,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.attendance, "dateTimeOut", $event)},"update:return-value":function($event){return _vm.$set(_vm.attendance, "dateTimeOut", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-clock-time-four-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.attendance.dateTimeOut),callback:function ($$v) {_vm.$set(_vm.attendance, "dateTimeOut", $$v)},expression:"attendance.dateTimeOut"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.endTimeModal),callback:function ($$v) {_vm.endTimeModal=$$v},expression:"endTimeModal"}},[(_vm.endTimeModal)?_c('v-date-picker',{attrs:{"scrollable":"","full-width":""},model:{value:(_vm.attendance.dateTimeOut),callback:function ($$v) {_vm.$set(_vm.attendance, "dateTimeOut", $$v)},expression:"attendance.dateTimeOut"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.endTimeModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogs.save(_vm.attendance.dateTimeOut)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","center":"","color":"success lighten-5 success--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"success darken-3"},on:{"click":function($event){_vm.snackSuccess = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackSuccess),callback:function ($$v) {_vm.snackSuccess=$$v},expression:"snackSuccess"}},[_c('span',[_vm._v(_vm._s(_vm.$t("attendance added successfully"))+"!")])]),_c('v-snackbar',{attrs:{"top":"","center":"","color":"red lighten-5 error--text text--darken-3","timeout":4000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"error darken-3"},on:{"click":function($event){_vm.snackError = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("close")])],1)]}}]),model:{value:(_vm.snackError),callback:function ($$v) {_vm.snackError=$$v},expression:"snackError"}},[_c('span',[_vm._v(_vm._s(_vm.$t("could not add an attendance. Try again later")))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }